<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        DB관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              :loading="loading"
              color="primary"
              @click="getSummary()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <div>
        <v-btn
          depressed
          color="success"
          class="ml-2"
          @click="dialog.file.show = true"
          v-if="checkFuncPermission('regist')"
        >
          DB등록(엑셀)
        </v-btn>
      </div>
    </div>
    <div class="main-section">
      <search-filter
        :selectFilters="selectFilters"
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="이름/전화번호/메모"
        @updateRadio="updateRadio"
        @updateSearch="updateSearch"
        @updateSelect="updateSelect"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col
          class="d-flex align-center pl-0"
        >
          <p class="mb-0 mt-4">
            <v-chip color="primary" label outlined class="pr-2 pl-2 mr-4">
              총: {{ totalItems.toLocaleString('ko-KR') }} 건
            </v-chip>
          </p>
          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="10개씩 보기"
              :value="10"
            ></v-radio>
            <v-radio
              small
              label="20개씩 보기"
              :value="20"
            ></v-radio>
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <div class="edit-btn-wrap d-flex">
          <v-chip
            label
            link
            class="pl-2 ml-2 d-flex align-center"
            :disabled="selected.length === 0"
            @click="getSalesStaffsList(true)"
            v-if="checkFuncPermission('manager')"
            color="accent"
          >
            <v-icon size="20" class="mr-2">mdi-account-plus-outline</v-icon>
            담당자일괄배정({{ selected.length.toLocaleString('ko-KR') }})
          </v-chip>
        </div>
      </v-row>
      <data-table
        :items="lists"
        :sorts.sync="sorts"
        :headers="headers"
        :selectable="checkFuncPermission('manager') === true"
        :selected.sync="selected"
        dense
        itemKey="no"
        :loading="loading"
      >
        <template #phone="{ item }">
          <v-tooltip top color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ `${comm.makePrivatePhoneNumber(item.phone)}` }}
              </span>
            </template>
            <span>{{ item.phone }}</span>
          </v-tooltip>
        </template>
        <template #sourceName="{ item }">
          <span :class="item.sourceName == null?'un-source':''">
            {{ item.sourceName == null?'미등록':item.sourceName }}
          </span>
        </template>
        <template #created="{ item }">
          <span>
            {{ time.makeLocalTime(item.created, 'min') }}
          </span>
        </template>
        <template #memo="{ item }">
          <span
            v-if="item.memo == null || item.memo.trim().length <= 0"
            class="un-source"
          >
            메모 없음
          </span>
          <span v-else>
            {{ item.memo.trim() }}
          </span>
        </template>
        <template #flag="{ item }">
          <v-menu offset-y
            v-if="item.flag === false
              && checkFuncPermission('manager')"
          >
            <template #activator="{ on, attrs }">
              <v-chip small label link outlined
                v-bind="attrs"
                v-on="on"
                @click="getSalesStaffsList"
              >미배정</v-chip>
            </template>
            <v-text-field
              :autofocus="true"
              dense
              flat
              hide-details
              v-model="saleStaffsText"
              placeholder="담당자 검색"
              @input="salesStaffsFiltering"
              color="black"
              outlined
              solo
            ></v-text-field>
            <v-list v-if="salesStaffsFilter.length > 0" class="search-picker-list">
              <v-list-item
                v-for="(row, i) in salesStaffsFilter"
                :key="i"
                dense
                nav
                link
                @click="contactAlloc({items: [{ contactNo: item.no, id: row.id }]})"
              >
                <v-list-item-title>
                  <v-chip small class="pr-1 pl-1 mr-1" link outlined label>{{ row.group }}</v-chip>
                  {{ row.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else-if="salesStaffsFilter.length === 0" class="search-picker-list">
              <v-list-item dense>
                <v-list-item-title>{{
                  saleStaffsText == null
                  || saleStaffsText.trim().length === 0
                  ?'검색어를 입력해주세요.'
                  :'데이터가 없습니다.'
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-chip small color="primary" v-else-if="item.flag === true">배정완료</v-chip>
          <v-chip small v-else-if="item.flag === false">미배정</v-chip>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      @click:outside="dialog.setSales.show = false;"
      v-model="dialog.setSales.show"
      width="600px"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <div class="text-h5 d-flex align-center">
            담당자일괄배정
            <v-chip small class="ml-2">
              선택고객: {{ selected.length.toLocaleString('ko-KR') }} 건
            </v-chip>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            :autofocus="true"
            dense
            flat
            hide-details
            v-model="saleStaffsText"
            placeholder="담당자 검색"
            @input="salesStaffsFiltering"
            color="primary"
            outlined
            solo
          ></v-text-field>
          <v-list v-if="salesStaffsFilter.length > 0" class="sales-select-list">
            <v-list-item-group
              v-model="dialog.setSales.sales"
              color="primary"
            >
              <v-list-item
                v-for="(item, index) in salesStaffsFilter"
                :key="index"
                dense
                nav
                link
                :value="item"
              >
                <v-list-item-title>
                  <v-chip small class="pr-1 pl-1 mr-1" outlined label>{{ item.group }}</v-chip>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list v-else-if="salesStaffsFilter.length === 0" class="sales-select-list">
            <v-list-item dense>
              <v-list-item-title>{{
                saleStaffsText == null
                || saleStaffsText.trim().length === 0
                ?'검색어를 입력해주세요.'
                :'데이터가 없습니다.'
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pl-6 pt-6 justify-end">
          <v-btn
            color="primary"
            depressed
            :disabled="dialog.setSales.sales == null"
            @click="contactAlloc()"
          >
            담당자배정
            <div v-if="dialog.setSales.sales != null"> ({{ dialog.setSales.sales.fullName }})</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.file.show"
      @click:outside="fileInit()"
      @keydown="$event.key === 'Escape' ? fileInit() : ''"
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center justify-space-between"
        >
          <span class="text-h5">엑셀등록</span>
          <div class="textBox">
            <v-text-field
              class="mb-2"
              label="유입업체명(1~10자)"
              outlined
              :rules="[
                (value) => value !== null
                && value.length >= 1
                && value.length <= 10
                ? true : '1~10자로 입력해주세요.'
              ]"
              hide-details
              v-model="sourceName"
              dense
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text>
          <input type="file" class="file-input" ref="fileInput"
            @change="fileZoneFileChange"
          />
          <div class="file-zone"
            v-if="dialog.file.data === null"
            :class="isDragged ? 'dragged' : ''"
            @dragenter="fileZoneDragenter"
            @dragover="fileZoneDragover"
            @dragleave="fileZoneDragleave"
            @drop="fileZoneDrop"
            @click="fileZoneClick"
          >
            <v-icon large class="mb-2">mdi-cloud-upload-outline</v-icon>
            <p class="mb-2">엑셀파일을 여기에 끌어오거나 클릭하여 파일을 선택하세요.</p>
          </div>
          <div class="file-zone-dup" v-else>
            <v-chip
              class="file-chip"
              close
              label
              color="success"
              @click:close="dialog.file.data = null"
            >
              <span>
                {{ dialog.file.data.name }}({{ fileUtils.byteTrans(dialog.file.data.size) }})
              </span>
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="dialog.file.data === null
              || sourceName == null
              || sourceName.trim().length <= 0"
            @click="uploadFileFunc"
          >
            업로드
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.result.show"
      persistent
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">직원등록결과</span>
          <v-chip small class="ml-2" color="success">성공: {{ dialog.result.successCnt }}건</v-chip>
          <v-chip small class="ml-1" color="error">실패: {{ dialog.result.failCnt }}건</v-chip>
        </v-card-title>
        <v-card-text>
          <v-simple-table ref="resultTable">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    이름(아이디)
                  </th>
                  <th class="text-left">
                    비밀번호
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dialog.result.data"
                  :key="item.id"
                >
                  <td>{{ item.name }}({{ item.id }})</td>
                  <td>{{ item.newPassword }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="resultTableCopy"
          >
            <v-icon small class="mr-1">mdi-content-copy</v-icon>
            결과복사하기
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="dialog.result.show = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import time from '@/util/time';
import comm from '@/util/comm';
import axios from 'axios';
import path from '@/util/path';
import fileUtils from '@/util/file';
import debounce from 'debounce';

export default {
  name: 'Contact',
  components: {
    SearchFilter,
    DataTable,
  },
  data: () => ({
    comm,
    time,
    fileUtils,
    isDragged: false,
    dialog: {
      setSales: {
        show: false,
        sales: null,
      },
      result: {
        show: false,
        successCnt: 0,
        failCnt: 0,
        data: [],
      },
      file: {
        show: false,
        data: null,
      },
    },
    sourceName: null,
    groups: [],
    salesStaffs: [],
    salesStaffsFilter: [],
    saleStaffsText: null,
    selected: [],
    loading: true,
    type: 0,
    dateFilter: {
      label: 'DB등록일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    lists: [],
    radioFilters: {
      flag: {
        key: 'flag',
        name: '배정여부',
        value: false,
        data: [
          { name: '전체', value: '전체' },
          { name: '미배정', value: false },
          { name: '배정완료', value: true },
        ],
      },
    },
    selectFilters: {
      sourceNo: {
        key: 'sourceNo',
        name: '유입업체',
        value: -1,
        data: [],
      },
    },
    search: '',
    totalItems: 0,
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ['created', 'name'],
      sortDesc: ['DESC', 'ASC'],
      filters: [
        { condition: 'ge', column: 'created', value: time.makeUTCDate(time.moment().add('-1', 'Y').format('YYYY-MM-DD')) },
        {
          where: 'and',
          condition: 'le',
          column: 'created',
          value: `
            ${time.makeUTCDate(time.moment().format('YYYY-MM-DD'))} 23:59:59
          `,
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'flag',
          value: false,
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'phone',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'name',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'memo',
              value: '',
            },
          ],
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'sourceNo',
          value: '',
        },
      ],
    },
    headers: [
      {
        text: '배정여부',
        value: 'flag',
        align: 'center',
        width: 100,
        sortable: false,
      },
      {
        text: '이름',
        value: 'name',
        align: 'center',
        width: 80,
        sortable: false,
      },
      {
        text: '전화번호',
        value: 'phone',
        align: 'center',
        width: 130,
        sortable: false,
      },
      {
        text: '유입업체',
        value: 'sourceName',
        align: 'center',
        width: 120,
        sortable: false,
      },
      {
        text: '메모',
        value: 'memo',
        align: 'left',
        sortable: false,
      },
      {
        text: 'DB등록일시',
        value: 'created',
        align: 'center',
        width: 150,
        sortable: false,
      },
    ],
    sorts: {
      sortBy: ['created', 'no', 'sourceName'],
      sortDesc: [true, true, true],
    },
    excelDownloadList: [],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
    },
    getSalesStaffsList(modal = false) {
      this.salesStaffs = [];
      this.salesStaffsFilter = [];
      this.saleStaffsText = null;
      this.dialog.setSales.sales = null;
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['groupNo'],
        sortDesc: [false],
        filters: [{ condition: 'eq', column: 'is_sales', value: 1 }],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = this.comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const user = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.salesStaffs.push(user);
          });
          this.salesStaffsFilter = this.salesStaffs;
          if (modal === true) {
            this.dialog.setSales.show = true;
            this.dialog.setSales.sales = null;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    salesStaffsFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.salesStaffs.filter((r) => r.fullName.indexOf(e) !== -1);
        this.salesStaffsFilter = result;
      } else {
        this.salesStaffsFilter = [];
      }
    },
    getSummary() {
      this.selected = [];
      this.lists = [];
      this.loading = true;
      this.$socket.emit('contact.summary.get', {
        filters: this.options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('contact.list.get', this.options, (response) => {
            if (response.result === 'success') {
              this.lists = response.items;
              this.totalItems = resp.item.count;
              this.loading = false;
              if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
                this.options.page = 1;
              }
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              console.error(response);
              this.loading = false;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getContactList() {

    },
    updateOptions(type, value) {
      let valArr = [];
      if (value === 'sourceNo') {
        valArr = ['source_no'];
      } else {
        valArr = [value];
      }
      this.options[type] = valArr;
    },
    contactAlloc(obj = null) {
      this.progress(true);
      const reqObj = obj === null ? { items: [] } : obj;
      if (obj === null) {
        this.selected.forEach((no) => {
          reqObj.items.push({ id: this.dialog.setSales.sales.id, contactNo: no });
        });
      }
      this.$socket.emit('contact.alloc', reqObj, (resp) => {
        if (resp.result === 'success') {
          this.getSummary();
          this.alert(['success', '담당자배정이 완료되었습니다.']);
          this.dialog.setSales.show = false;
          this.dialog.setSales.sales = null;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    resultTableCopy() {
      const table = this.$refs.resultTable.$el.innerText;
      navigator.clipboard.writeText(table);
      this.alert(['success', '클립보드에 복사되었습니다.']);
    },
    fileInit() {
      this.dialog.file.show = false;
      this.dialog.file.data = null;
      this.$refs.fileInput.value = null;
    },
    fileZoneClick() {
      this.$refs.fileInput.click();
    },
    fileZoneDragenter() {
      this.isDragged = true;
    },
    fileZoneDragleave() {
      this.isDragged = false;
    },
    fileZoneDragover(event) {
      event.preventDefault();
    },
    fileZoneDrop(event) {
      event.preventDefault();
      this.isDragged = false;
      if (event.dataTransfer.files.length !== 1) {
        this.alert(['error', '파일은 1개만 등록가능합니다.', 4000]);
      } else {
        this.uploadeFileCheck(event.dataTransfer.files[0]);
      }
    },
    fileZoneFileChange(event) {
      this.uploadeFileCheck(event.target.files[0]);
    },
    uploadeFileCheck(file) {
      if (file != null) {
        const typeArr = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
        ];
        const typeCheck = typeArr.includes(file.type);
        const sizeCheck = file.size <= 10485760;
        if (typeCheck === false) {
          this.alert(['error', '엑셀파일만 업로드 가능합니다.']);
          this.dialog.file.data = null;
        } else if (sizeCheck === false) {
          this.alert(['error', '최대 10MB 까지 업로드 가능합니다.']);
          this.dialog.file.data = null;
        } else {
          this.dialog.file.data = file;
        }
      }
      this.$refs.fileInput.value = null;
    },
    uploadFileFunc() {
      this.progress(true);
      const data = new FormData();
      data.append('source', this.sourceName.trim());
      data.append('file', this.dialog.file.data);
      const token = localStorage.getItem(`MANOadmin.${this.companyInfo.code}.authToken`);
      const headers = {
        headers: {
          token,
        },
      };
      axios.post(`${path.apiHost}${path.apiHostBase}/contact`, data, headers)
        .then(() => {
          this.dialog.file.show = false;
          this.dialog.file.data = null;
          this.alert(['success', '업로드가 진행중입니다. 진행도는 우측상단에서 확인해볼 수 있습니다.']);
          this.progress(false);
        })
        .catch((err) => {
          console.error(err);
          this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
          this.progress(false);
        });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.cs.menu.db.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      this.$socket.emit('contact.list.get', {
        ...this.options,
        itemsPerPage: 0,
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.items.length <= 0) {
            this.alert(['error', '다운로드할 목록이 없습니다.']);
            this.excelDownloadList = [];
            this.progress(false);
          } else {
            const list = resp.items.reduce((arr, r) => {
              const row = {};
              row['이름'] = r.name;
              row['전화번호'] = comm.makePrivatePhoneNumber(r.phone);
              row['DB등록일시'] = time.makeLocalTime(r.created, 'min');
              row['유입업체'] = r.sourceName === null ? '-' : r.sourceName;
              row['메모'] = r.memo === null ? '-' : r.memo;
              row['배정여부'] = r.flag === true ? '배정완료' : '미배정';
              arr.push(row);
              return arr;
            }, []);
            this.excelDownloadList = list;
            comm.makeExcelFile(this.excelDownloadList, `DB목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.excelDownloadList = [];
        }
      });
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    dateFilter: {
      deep: true,
      handler(val) {
        if (this.userInfo != null && val.endPicker === false && val.startPicker === false) {
          this.options.filters[0].value = time.makeUTCDate(val.startDate);
          this.options.filters[1].value = time.makeUTCDate(
            `${val.endDate} 23:59:29`,
          );
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const value = val.flag.value === '전체' ? '' : val.flag.value;
          this.options.filters[2].value = value;
        }
      },
    },
    selectFilters: {
      deep: true,
      handler(val) {
        const sorceNo = val.sourceNo.value;
        if (sorceNo === -1) {
          this.options.filters[4].condition = 'inc';
          this.options.filters[4].value = '';
        } else {
          this.options.filters[4].condition = 'eq';
          this.options.filters[4].value = sorceNo;
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[3].filters[0].value = val;
          this.options.filters[3].filters[1].value = val;
          this.options.filters[3].filters[2].value = val;
        }
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getSummary = debounce(this.getSummary, 300);
          this.$socket.emit('groups.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['no'],
            sortDesc: [false],
            filters: [],
          }, (resp) => {
            if (resp.result === 'success') {
              this.groups = resp.items.groups;
              this.$socket.emit('contact.source.list.get', {
                page: 1,
                itemsPerPage: 0,
                sortBy: [],
                sortDesc: [],
                filters: [{ condition: 'eq', column: 'disabled', value: 0 }],
              }, (response) => {
                if (response.result === 'success') {
                  const sourceArray = [
                    { name: '전체', value: -1 },
                  ];
                  sourceArray.push(...response.items
                    .map((r) => ({ name: r.name, value: r.no })));
                  this.selectFilters.sourceNo.data = sourceArray;
                  this.getSummary();
                  this.progress(false);
                } else {
                  this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
                  console.error(resp);
                }
              });
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
            }
          });
          this.getSummary();
        }
      },
    },
    dialog: {
      deep: true,
      handler() {
      },
    },
  },
};
</script>

<style scoped lang="scss">
.edit-btn-wrap {
  margin-bottom:-20px;
}
.scroll-wrap {
  max-height: 200px;
  overflow-y: auto;
}
.select-users {
  margin-top:10px;
  padding-bottom:6px;
  padding-left: 6px;
  border-radius: 4px;
  border:1px solid #ececec;
  width: 100%;
  background-color: #fff;
  max-height: 500px;
  overflow: auto;
}
.sales-select-list {
  border:1px solid #ddd;
  border-radius: 5px;
  margin-top:4px;
  max-height: 500px;
  overflow: auto;
}
.un-source {
  color: #aaa;
}
.file-input {
  display: none;
}
.file-zone {
  width: 100%;
  margin-top:20px;
  height: 150px;
  border:3px dashed #ccc;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  opacity: .7;
  cursor: pointer;
  transition: .3s ease-in-out;
  color: #000;
  font-size: .9rem;
  flex-wrap: wrap;
  align-content: center;
  &:hover {
    opacity: 1;
    border-color: skyblue;
  }
  p {
    margin:0;
    width: 100%;
    text-align: center;
  }
  &.dragged {
    opacity: 1;
    border-color: skyblue;
  }
}
.file-zone-dup {
  width: 100%;
  margin-top:20px;
  margin-bottom:-10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .file-chip {
    width: 100%;
    justify-content: center;
    span {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.search-picker-list {
  max-height: 400px;
  overflow: auto;
}
.content-wrap {
  .text-input-wrap {
    width: 280px;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    .pay-list {
      .pay-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top:0px;
        .pay-info {
          display: flex;
          align-items: center;
          .pay-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .pay-form {
        padding: 10px;
        min-width: 500px;
        max-width: 500px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:-30px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
    }
    .section-wrap {
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
</style>
